module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Physiotherapie mitbewegt","short_name":"mitbewegt","start_url":"/","background_color":"#663399","theme_color":"#acc79f","display":"minimal-ui","icon":"src/images/mitbewegt-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1146c682bd524b4fbb46fd09c09ee106"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
